import Page from '../templates/Page'

const ProjectsPage = () => {
  return (
    <Page>
      <h1>Projects</h1>
      <p>Personal projects</p>
      <p>Under Contruction</p>
    </Page>
  )
}

export default ProjectsPage
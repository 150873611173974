import Page from '../templates/Page'

const OthersPage = () => {
  return (
    <Page>
      <h1>Others</h1>
      <p>Other pages</p>
      <ul>
        <li><a href='http://archive.5iq.cc' target='_blank' rel="noreferrer">Archive</a></li>
      </ul>
      <p>Under Contruction</p>
    </Page>
  )
}

export default OthersPage
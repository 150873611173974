const toHome = () => { console.log('to home') }
const toCareer = () => { console.log('to career') }
const toProjects = () => { console.log('to projects') }
const toWritings = () => { console.log('to writings') }
const toGallery = () => { console.log('to paintings') }

export {
  toHome,
  toCareer,
  toProjects,
  toWritings,
  toGallery
}